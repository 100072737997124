<template>
    <div class="action-schedule-agent-unarrangeable">
        <div class="title f-bold" v-html="titleMessage" />
        <div v-if="changeType === 'cancel'" class="body">
            <b>{{ `${content.user_name}(${content.user_id})` }}</b
            >님이 약속을 임의로 취소했습니다. <b>{{ `${content.target_name}(${content.target_id})` }}</b
            >님에게 다시 한번 사과 말씀을 전해주세요. 그리고 취소 사유를 확인하고 수수료 부과 여부 및 금액을 확정해서
            안내해주세요.
        </div>
        <div v-else class="body">
            <b>{{ `${content.user_name}(${content.user_id})` }}</b
            >님이 약속 변경을 위해 <b>{{ `${content.target_name}(${content.target_id})` }}</b
            >님과의 채팅방을 오픈했습니다. 채팅방 내용을 확인하고 수수료 부과 여부 및 금액을 확정해서 안내해주세요.
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleAgentUnarrangeable',
    props: ['message'],
    components: {},
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        getUser() {
            return person => {
                return {
                    photo: person.urls[0],
                    name: person.name,
                    birthday: person.birthday,
                }
            }
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        changeType() {
            return this.content.change_type
        },
        titleMessage() {
            if (this.changeType === 'cancel') {
                return '회원이 임의로 약속을 취소했습니다.'
            } else {
                return '회원끼리 약속 변경을 하고있습니다.'
            }
        },
    },
    methods: {},
}
</script>

<style scoped lang="scss">
.action-schedule-agent-unarrangeable {
    .title {
        padding: 0;
    }
    .body {
        padding: 12px 0 0;
    }
    width: 100%;
    padding: 16px;
    .meeting-info {
        width: 100%;
    }
}
</style>
